<template>
	<base-col col="12">
		<div class="product-description">
			<base-font class="heading" variant="medium-header" tag="h2" marginReset>
				{{ value.heading }}
			</base-font>
			<base-font class="description" color="primary" :html="value.description"></base-font>
		</div>
	</base-col>
</template>

<script>
export default {
	props: {
		prefix: String,
		value: Object
	}
}
</script>

<style scoped lang="scss">
.product-description {
	display: flex;
	flex-direction: column;
	align-items: center;
	justify-content: center;
	text-align: center;

	:deep(.heading) {
		padding-bottom: 1rem;

		@include media-breakpoint-up(xl) {
			padding-bottom: 3rem;
		}
	}

	:deep(.description) {
		font-family: $primary-font;
		text-align: center;

		@include media-breakpoint-up(xl) {
			padding: 0 8rem;
		}

		p {
			letter-spacing: 0.29px;
			line-height: 20px;
		}
	}

	:deep(.motto) {
		padding-top: 2rem;
	}
}
</style>
